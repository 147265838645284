import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-future-clients',
  templateUrl: './future-clients.component.html',
  styleUrls: ['./future-clients.component.scss']
})
export class FutureClientsComponent implements OnInit {
  show = 5;
  
  readonly url = "./../../../assets/json/future-clients.json";
  clients: any;

  filters = {
    sub1: null,
  }

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  showmore() {
    this.show = this.show + 5;
  }

  filter() {
    var target = (event.target as HTMLInputElement).innerText;
	this.show = this.clients.length;
	target = target.trim();
    //console.log(target)
    if (target == 'Future Events') {
      this.filters.sub1 = 'FUTURE EVENTS';
    }
  }

  reset() {
    this.filters.sub1 = null;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['sub1']) {
        this.filters.sub1 = params['sub1'];
      }
    })
    this.clients = this.http.get(this.url)
  }
}
