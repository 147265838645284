import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  show = 5;
  
  readonly url = "./../../../assets/json/insights.json";
  posts: any;

  filters = {
    sub1: null,
  }

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  showmore() {
    this.show = this.show + 5;
  }

  filter() {
    var target = (event.target as HTMLInputElement).innerText;
	this.show = this.posts.length;
	target = target.trim();
    //console.log(target)
    if (target == 'Blog') {
      this.filters.sub1 = 'BLOG';
    }
    if (target == 'Planning') {
      this.filters.sub1 = 'PLANNING';
    }
    if (target == 'Investing') {
      this.filters.sub1 = 'INVESTING';
    }
    if (target == 'Market Update') {
      this.filters.sub1 = 'MARKET UPDATE';
    }
  }

  reset() {
    this.filters.sub1 = null;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['sub1']) {
        this.filters.sub1 = params['sub1'];
      }
    })
    this.posts = this.http.get(this.url)
  }

}
