import { Component, Input, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Angulartics2 } from "angulartics2";
import { Location } from "@angular/common";
import {
    FormGroup,
    Validators,
    AbstractControl,
    FormControl,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-solely",
    templateUrl: "./solely.component.html",
    styleUrls: ["./solely.component.scss"],
})
export class SolelyComponent implements OnInit {
    @Input() cat: string;
    @Input("title") title: any;
    @Input("endphrase") endphrase: boolean;

    public formContact: FormGroup;

    constructor(
        private http: Http,
        @Inject(PLATFORM_ID) private platformId: any,
        public location: Location,
        public angulartics2: Angulartics2,
        private route: ActivatedRoute
    ) {}

    mailStatus = {
        sended: false,
        error: false,
    };

    showcaptcha: boolean = false;

    trySubmit = false;

    smtp_server = `${environment.smtp_url}?env=${
        environment.production ? "prod" : "dev"
    }`;

    env: any = {};

    accentedCharacters =
        "àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ";

    ngOnInit() {
        if (this.endphrase === undefined) {
            this.endphrase = true;
        }

        this.env = environment;

        this.formContact = new FormGroup({
            email: new FormControl("", [
                Validators.required,
                Validators.pattern(
                    "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                ),
            ]),
            recaptchatoken: new FormControl("", [Validators.required]),
            type: new FormControl(
                this.cat === "newsletter" ? this.cat : "services"
            ),
            section: new FormControl(this.title),
        });
    }

    get email() {
        return this.formContact.get("email");
    }
    get recaptchatoken() {
        return this.formContact.get("recaptchatoken");
    }

    clearForm() {
        this.formContact.reset();
    }

    submit(e): void {
        e.preventDefault();
        this.trySubmit = true;

        if (this.formContact.valid) {
            const path =
                this.route.snapshot["_routerState"].url === "/"
                    ? this.route.snapshot["_routerState"].url
                    : `${this.route.snapshot["_routerState"].url}/`;

            this.http
                .post(this.smtp_server, this.formContact.value)
                .toPromise()
                .then((res) => {
                    if (res.status === 200) {
                        this.mailStatus.error = false;
                        this.mailStatus.sended = true;
                        const succesPath =
                            this.cat === "newsletter"
                                ? `${path}newsletter-success`
                                : `${path}success`;
                        this.location.replaceState(succesPath);
                        this.formContact.reset();

                        if (
                            environment.production &&
                            isPlatformBrowser(this.platformId)
                        ) {
                            this.angulartics2.pageTrack.next({
                                path: succesPath,
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.mailStatus.error = true;
                    this.mailStatus.sended = false;
                    this.location.replaceState(`${path}error`);

                    if (
                        environment.production &&
                        isPlatformBrowser(this.platformId)
                    ) {
                        this.angulartics2.pageTrack.next({
                            path: `${path}error`,
                        });
                    }
                });
        }
    }
}
