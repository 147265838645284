import { Component } from '@angular/core';

@Component({
  selector: 'app-smartvestor',
  templateUrl: './smartvestor.component.html',
  styleUrls: ['./smartvestor.component.scss']
})
export class SmartvestorComponent{
  SmartVestor:string = 'SmartVestor';
  constructor() { }

}