import { Component } from '@angular/core';

@Component({
  selector: 'app-fiduciary',
  templateUrl: './fiduciary.component.html',
  styleUrls: ['./fiduciary.component.scss']
})
export class FiduciaryComponent{
  show = false;

}