import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {
  show = 5;
  
  readonly url = "./../../../assets/json/press.json";
  posts: any;

  filters = {
    sub1: null,
  }

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  showmore() {
    this.show = this.show + 5;
  }

  filter() {
    var target = (event.target as HTMLInputElement).innerText;
	this.show = this.posts.length;
	target = target.trim();
    // console.log(target)
    if (target == 'GDS News') {
      this.filters.sub1 = 'GDSNEWS';
    }
    if (target == 'Press') {
      this.filters.sub1 = 'PRESS';
    }
  }

  reset() {
    this.filters.sub1 = null;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['sub1']) {
        this.filters.sub1 = params['sub1'];
      }
    })
    this.posts = this.http.get(this.url)
  }

  navigate(url:string):void{
    // this.httpRouter.navigate(['/externalRedirect', { externalUrl: url }]);
    window.open(url, '_blank');
  }

}
