import { Component } from '@angular/core';

@Component({
  selector: 'app-cfp',
  templateUrl: './cfp.component.html',
  styleUrls: ['./cfp.component.scss']
})

export class CFPComponent{
  activeCategory = 0;

  setActiveCategory(category){
    if (this.activeCategory == category){
      this.activeCategory = 0;
    } else{
      this.activeCategory = category;
    }
  }
}
