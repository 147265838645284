import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
@Injectable()
export class FilterPipePress implements PipeTransform {
  transform(items: any[], sub1:string, sub2:string):any {
	  if(items != null){
		return items.filter(item => {
			// FILTER LOGIC
			let ret = true;
			
			if (sub1 && sub1 !== item.sub1) ret = false;
			if (ret && sub2 && sub2 !== item.sub2) ret = false;
			
			if (ret) {
			  return item;
			}
		  });
	  }
  }
}