import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-press-list',
  templateUrl: './press-list.component.html',
  styleUrls: ['./press-list.component.scss']
})
export class PressListComponent{
  readonly url = "./../../../assets/json/press.json";
  posts: any;
  mobile: boolean;
  final : number;
  initial = 0;
  
  constructor(private http: HttpClient) {}
  
  ngOnInit() {
    this.posts = this.http.get(this.url)

    this.mobile = /Android|webOS|iPhone|iPod/i.test(window.navigator.userAgent)||
    (window.navigator.userAgent === 'MacIntel' && navigator.maxTouchPoints > 1);
    
    this.final = this.mobile ? 1 : 3 ;
  }

  navigate(url:string):void{
    // this.httpRouter.navigate(['/externalRedirect', { externalUrl: url }]);
    window.open(url, '_blank');
    
  }
}
