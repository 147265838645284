import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-insights-list',
  templateUrl: './insights-list.component.html',
  styleUrls: ['./insights-list.component.scss']
})
export class InsightsListComponent{
  readonly url = "./../../../assets/json/insights.json";
  posts: any;
  final = Math.floor(Math.random() * 20);
  initial = (this.final - 3);
  
  mobile: boolean;
  // final : number;
  // initial = 0;
  
  constructor(private http: HttpClient) {}
  
  ngOnInit() {
    this.posts = this.http.get(this.url)

    this.mobile = /Android|webOS|iPhone|iPod/i.test(window.navigator.userAgent)||
    (window.navigator.userAgent === 'MacIntel' && navigator.maxTouchPoints > 1);
    
    // this.final = this.mobile ? 1 : 3 ;

    // Mostrar 1 sólo insight list. Queda mal en iPad
    this.final = this.mobile ? this.final - 2 : this.final;

    // console.log("test", this.url);
  }
}
