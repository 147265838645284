import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMobileMenu = false;
  showInsights = false;
  showWhoWeAre = false;
  showEvents = false;
  showContactUs = false;

  links = [
    {path: '/', label: 'Home'},
    {path: '/whoweare', label: 'Who We Are'},
    {path: '/whatwedo', label: 'What We Do'},
    {path: '/insights', label: 'Insights'},
    {path: '/current-clients', label: 'Events'},
    // {path: '/press', label: 'Press'},
    {path: 'https://clientaccess.rjf.com/', label: 'Client Access'},
    {path: '/contact-us', label: 'Contact Us'},
  ];

  /*linksMobile = [
    {path: '/', label: 'Home'},
    {path: '/whoweare', label: 'Who We Are'},
    {path: '/whatwedo', label: 'What We Do'},
    {path: '/insights', label: 'Blog'},
    {path: '/press', label: 'Press'},
    {path: 'https://clientaccess.rjf.com/', label: 'Client Access'},
    {path: '/contact-us', label: 'Contact Us'},
  ];*/

  collapsed = true;
  
  constructor() {
  }

  ngOnInit() {
  }

  stop(event){
    event.stopPropagation();
  }
  toggleMenu(){
    event.stopPropagation();
    this.showMobileMenu = !this.showMobileMenu;
  }
}
