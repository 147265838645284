import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Angulartics2Module } from "angulartics2";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { JwSocialButtonsModule } from "jw-angular-social-buttons";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/home/home.component";
import { ComponentGuideComponent } from "./pages/component-guide/component-guide.component";
import { WhoWeAreComponent } from "./pages/whoweare/overview/overview.component";
import { WhatWeDoComponent } from "./pages/whatwedo/overview/overview.component";
import { WhatWeDoTab1Component } from "./pages/whatwedo/tabs/wwdtab1.component";
import { WhatWeDoTab2Component } from "./pages/whatwedo/tabs/wwdtab2.component";
import { WhatWeDoTab3Component } from "./pages/whatwedo/tabs/wwdtab3.component";
import { ContactUsComponent } from "./pages/contactus/contactus.component";
import { CareersComponent } from "./pages/careers/careers.component";
import { CFPComponent } from "./pages/whoweare/cfp/cfp.component";
import { SmartvestorComponent } from "./pages/whoweare/smartvestor/smartvestor.component";
import { ForbesComponent } from "./pages/whoweare/forbes/forbes.component";
import { FiduciaryComponent } from "./pages/whoweare/fiduciary/fiduciary.component";
import { RaymondJamesComponent } from "./pages/whoweare/raymond-james/raymond-james.component";
import { Service1Component } from "./pages/services/service-1/service.component";
import { Service2Component } from "./pages/services/service-2/service.component";
import { Service3Component } from "./pages/services/service-3/service.component";
import { Service4Component } from "./pages/services/service-4/service.component";
import { Service5Component } from "./pages/services/service-5/service.component";
import { Service6Component } from "./pages/services/service-6/service.component";
import { Service7Component } from "./pages/services/service-7/service.component";
import { Service8Component } from "./pages/services/service-8/service.component";
import { Service9Component } from "./pages/services/service-9/service.component";
import { Service10Component } from "./pages/services/service-10/service.component";
import { InsightsComponent } from "./pages/insights/insights.component";
import { Post1Component } from "./pages/insights/posts/post-1/post.component";
import { Post2Component } from "./pages/insights/posts/post-2/post.component";
import { Post3Component } from "./pages/insights/posts/post-3/post.component";
import { Post4Component } from "./pages/insights/posts/post-4/post.component";
import { Post5Component } from "./pages/insights/posts/post-5/post.component";
import { Post6Component } from "./pages/insights/posts/post-6/post.component";
import { Post7Component } from "./pages/insights/posts/post-7/post.component";
import { Post8Component } from "./pages/insights/posts/post-8/post.component";
import { Post9Component } from "./pages/insights/posts/post-9/post.component";
import { Post10Component } from "./pages/insights/posts/post-10/post.component";
import { Post11Component } from "./pages/insights/posts/post-11/post.component";
import { Post12Component } from "./pages/insights/posts/post-12/post.component";
import { Post13Component } from "./pages/insights/posts/post-13/post.component";
import { Post14Component } from "./pages/insights/posts/post-14/post.component";
import { Post15Component } from "./pages/insights/posts/post-15/post.component";
import { Post16Component } from "./pages/insights/posts/post-16/post.component";
import { Post17Component } from "./pages/insights/posts/post-17/post.component";
import { Post18Component } from "./pages/insights/posts/post-18/post.component";
import { Post19Component } from "./pages/insights/posts/post-19/post.component";
import { Post20Component } from "./pages/insights/posts/post-20/post.component";
import { Post21Component } from "./pages/insights/posts/post-21/post.component";
import { Post22Component } from "./pages/insights/posts/post-22/post.component";
import { Post23Component } from "./pages/insights/posts/post-23/post.component";
import { Post24Component } from "./pages/insights/posts/post-24/post.component";
import { Post25Component } from "./pages/insights/posts/post-25/post.component";
import { Post26Component } from "./pages/insights/posts/post-26/post.component";
import { Post27Component } from "./pages/insights/posts/post-27/post.component";
import { Post28Component } from "./pages/insights/posts/post-28/post.component";
import { Post29Component } from "./pages/insights/posts/post-29/post.component";
import { Post30Component } from "./pages/insights/posts/post-30/post.component";
import { Post31Component } from "./pages/insights/posts/post-31/post.component";
import { Post32Component } from "./pages/insights/posts/post-32/post.component";
import { Post33Component } from "./pages/insights/posts/post-33/post.component";
import { Post34Component } from "./pages/insights/posts/post-34/post.component";
import { Post35Component } from "./pages/insights/posts/post-35/post.component";
import { Post36Component } from "./pages/insights/posts/post-36/post.component";
import { Post37Component } from "./pages/insights/posts/post-37/post.component";
import { Post38Component } from "./pages/insights/posts/post-38/post.component";
import { Post39Component } from "./pages/insights/posts/post-39/post.component";
import { Post40Component } from "./pages/insights/posts/post-40/post.component";
import { Post41Component } from "./pages/insights/posts/post-41/post.component";
import { Post42Component } from "./pages/insights/posts/post-42/post.component";
import { Post43Component } from "./pages/insights/posts/post-43/post.component";
import { Post44Component } from "./pages/insights/posts/post-44/post.component";
import { Post45Component } from "./pages/insights/posts/post-45/post.component";
import { Post46Component } from "./pages/insights/posts/post-46/post.component";
import { Post47Component } from "./pages/insights/posts/post-47/post.component";
import { Post48Component } from "./pages/insights/posts/post-48/post.component";
import { Post49Component } from "./pages/insights/posts/post-49/post.component";
import { Post50Component } from "./pages/insights/posts/post-50/post.component";
import { Post51Component } from "./pages/insights/posts/post-51/post.component";
import { Post52Component } from "./pages/insights/posts/post-52/post.component";
import { Post53Component } from "./pages/insights/posts/post-53/post.component";
import { Post54Component } from "./pages/insights/posts/post-54/post.component";
import { Post55Component } from "./pages/insights/posts/post-55/post.component";
import { Post56Component } from "./pages/insights/posts/post-56/post.component";
import { Post57Component } from "./pages/insights/posts/post-57/post.component";
import { Post58Component } from "./pages/insights/posts/post-58/post.component";
import { Post59Component } from "./pages/insights/posts/post-59/post.component";
import { Post60Component } from "./pages/insights/posts/post-60/post.component";
import { Post61Component } from "./pages/insights/posts/post-61/post.component";
import { Post62Component } from "./pages/insights/posts/post-62/post.component";
import { Post63Component } from "./pages/insights/posts/post-63/post.component";
import { Post64Component } from "./pages/insights/posts/post-64/post.component";
import { Post65Component } from "./pages/insights/posts/post-65/post.component";
import { Post66Component } from "./pages/insights/posts/post-66/post.component";
import { Post67Component } from "./pages/insights/posts/post-67/post.component";
import { Post68Component } from "./pages/insights/posts/post-68/post.component";
import { Post69Component } from "./pages/insights/posts/post-69/post.component";
import { Post70Component } from "./pages/insights/posts/post-70/post.component";
import { Post71Component } from "./pages/insights/posts/post-71/post.component";
import { Post72Component } from "./pages/insights/posts/post-72/post.component";
import { Post73Component } from "./pages/insights/posts/post-73/post.component";
import { Post74Component } from "./pages/insights/posts/post-74/post.component";
import { Post75Component } from "./pages/insights/posts/post-75/post.component";
import { Post76Component } from "./pages/insights/posts/post-76/post.component";
import { Post77Component } from "./pages/insights/posts/post-77/post.component";
import { Post78Component } from "./pages/insights/posts/post-78/post.component";
import { Post79Component } from "./pages/insights/posts/post-79/post.component";
import { Post80Component } from "./pages/insights/posts/post-80/post.component";
import { Post81Component } from "./pages/insights/posts/post-81/post.component";
import { Post82Component } from "./pages/insights/posts/post-82/post.component";
import { Post83Component } from "./pages/insights/posts/post-83/post.component";
import { Post84Component } from "./pages/insights/posts/post-84/post.component";
import { Post85Component } from "./pages/insights/posts/post-85/post.component";
import { PressComponent } from "./pages/press/press.component";
import { CurrentClientsComponent } from './pages/current-clients/current-clients.component';
import { FutureClientsComponent } from './pages/future-clients/future-clients.component';

import { HeaderComponent } from "./shared-components/header/header.component";
import { FooterComponent } from "./shared-components/footer/footer.component";
import { SolelyComponent } from "./shared-components/solely/solely.component";
import { InsightsListComponent } from "./shared-components/insights-list/insights-list.component";
import { PressListComponent } from "./shared-components/press-list/press-list.component";
import { CarouselComponent } from "./shared-components/carousel/carousel.component";
import { SmallCarouselComponent } from "./shared-components/small-carousel/small-carousel.component";
import { TabsComponent } from "./shared-components/tabs/tabs.component";
import { ShareComponent } from "./shared-components/share-button/share.component";

import { FilterPipe } from './pages/insights/filter';
import { FilterPipePress } from './pages/press/filter';
import { FilterPipeCurrentClients } from './pages/current-clients/filter';
import { FilterPipeFutureClients } from './pages/future-clients/filter';
import { ArraySortPipe } from './pages/insights/sort';
import { ArraySortPipePress } from './pages/press/sort';
import { ArraySortPipeCurrentClients } from './pages/current-clients/sort';
import { ArraySortPipeFutureClients } from './pages/future-clients/sort';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { CarouselOurTeamComponent } from "./pages/whoweare/overview/carousel-our-team.component";
import { Post86Component } from "./pages/insights/posts/post-86/post.component";
import { Post87Component } from "./pages/insights/posts/post-87/post.component";
import { Post88Component } from "./pages/insights/posts/post-88/post.component";
import { Post89Component } from "./pages/insights/posts/post-89/post.component";
import { Post90Component } from "./pages/insights/posts/post-90/post.component";
import { Post91Component } from "./pages/insights/posts/post-91/post.component";
import { Post92Component } from "./pages/insights/posts/post-92/post.component";
import { Post93Component } from "./pages/insights/posts/post-93/post.component";
import { Post94Component } from "./pages/insights/posts/post-94/post.component";
import { Post95Component } from "./pages/insights/posts/post-95/post.component";
import { Post96Component } from "./pages/insights/posts/post-96/post.component";
import { Post97Component } from "./pages/insights/posts/post-97/post.component";

const routes: Routes = [
    { path: "", component: HomeComponent, pathMatch: "full" },
    {
        path: "component-guide",
        component: ComponentGuideComponent,
        pathMatch: "full",
    },
    { path: "whoweare", component: WhoWeAreComponent, pathMatch: "full" },
    { path: "whoweare/cfp", component: CFPComponent, pathMatch: "full" },
    { path: "whoweare/forbes", component: ForbesComponent, pathMatch: "full" },
    { path: "whoweare/fiduciary", component: FiduciaryComponent, pathMatch: "full" },
    { path: "whoweare/raymond-james", component: RaymondJamesComponent, pathMatch: "full" },
    {
        path: "whoweare/smartvestor",
        component: SmartvestorComponent,
        pathMatch: "full",
    },
    { path: "whatwedo", component: WhatWeDoComponent, pathMatch: "full" },
    { path: "insights", component: InsightsComponent, pathMatch: "full" },
    { path: "contact-us", component: ContactUsComponent, pathMatch: "full" },
    { path: "careers", component: CareersComponent, pathMatch: "full" },
    { path: "press", component: PressComponent, pathMatch: "full" },
    { path: 'current-clients', component: CurrentClientsComponent, pathMatch: 'full'},
    { path: 'future-clients', component: FutureClientsComponent, pathMatch: 'full'},

    {
        path: "services/alternative-investments",
        component: Service1Component,
        pathMatch: "full",
    },
    {
        path: "services/cash-management",
        component: Service2Component,
        pathMatch: "full",
    },
    {
        path: "services/college-plan-savings",
        component: Service3Component,
        pathMatch: "full",
    },
    {
        path: "services/corporate-retirement-plan-consultation",
        component: Service4Component,
        pathMatch: "full",
    },
    {
        path: "services/estate-charitable-giving-trust",
        component: Service5Component,
        pathMatch: "full",
    },
    {
        path: "services/financial-planning",
        component: Service6Component,
        pathMatch: "full",
    },
    {
        path: "services/portfolio-management",
        component: Service7Component,
        pathMatch: "full",
    },
    {
        path: "services/prime-brokerage-custody-services",
        component: Service8Component,
        pathMatch: "full",
    },
    {
        path: "services/risk-management",
        component: Service9Component,
        pathMatch: "full",
    },

    {
        path: "insights/preserving-legacy-protecting-privacy",
        component: Post1Component,
        pathMatch: "full",
    },
    {
        path: "insights/homebuyer-guide-to-success",
        component: Post2Component,
        pathMatch: "full",
    },
    {
        path: "insights/navigating-medicare",
        component: Post3Component,
        pathMatch: "full",
    },
    {
        path: "insights/dont-let-emotion-drive",
        component: Post4Component,
        pathMatch: "full",
    },
    {
        path: "insights/check-this-list",
        component: Post5Component,
        pathMatch: "full",
    },
    {
        path: "insights/tax-planning-checklist",
        component: Post6Component,
        pathMatch: "full",
    },
    {
        path: "insights/women-are-a-game-changer",
        component: Post7Component,
        pathMatch: "full",
    },
    {
        path: "insights/welcoming-your-next-employee",
        component: Post8Component,
        pathMatch: "full",
    },
    {
        path: "insights/managing-cash",
        component: Post9Component,
        pathMatch: "full",
    },
    {
        path: "insights/effective-financial-plan",
        component: Post10Component,
        pathMatch: "full",
    },
    {
        path: "insights/managing-heisenberg",
        component: Post11Component,
        pathMatch: "full",
    },
    {
        path: "insights/why-trust-you",
        component: Post12Component,
        pathMatch: "full",
    },
    {
        path: "insights/three-to-dos-before-selling",
        component: Post13Component,
        pathMatch: "full",
    },
    {
        path: "insights/who-stands-behind-financial-advisor",
        component: Post14Component,
        pathMatch: "full",
    },
    {
        path: "insights/stay-on-target-when-emotions-run-high",
        component: Post15Component,
        pathMatch: "full",
    },
    {
        path: "insights/giving-your-loot-to-your-little-treasures",
        component: Post16Component,
        pathMatch: "full",
    },
    {
        path: "insights/saving-your-severance",
        component: Post17Component,
        pathMatch: "full",
    },
    {
        path: "insights/bbq-banter",
        component: Post18Component,
        pathMatch: "full",
    },
    {
        path: "insights/buying-home-for-christmas",
        component: Post19Component,
        pathMatch: "full",
    },
    {
        path: "insights/who-is-glen-smith",
        component: Post20Component,
        pathMatch: "full",
    },
    {
        path: "insights/look-like-christmas",
        component: Post21Component,
        pathMatch: "full",
    },
    {
        path: "insights/covid-19",
        component: Post22Component,
        pathMatch: "full",
    },
    {
        path: "insights/social-security-myths-and-misconceptions",
        component: Post23Component,
        pathMatch: "full",
    },
    {
        path: "insights/who-is-your-trusted-contact",
        component: Post24Component,
        pathMatch: "full",
    },
    {
        path: "insights/7-ways-to-boost-your-savings",
        component: Post25Component,
        pathMatch: "full",
    },
    {
        path: "insights/the-taxation-of-restricted-stock-units",
        component: Post26Component,
        pathMatch: "full",
    },
    {
        path: "insights/strategic-vs-tactical-investing",
        component: Post27Component,
        pathMatch: "full",
    },
    {
        path: "insights/end-of-the-year-money-moves",
        component: Post28Component,
        pathMatch: "full",
    },
    {
        path: "insights/november-market-review",
        component: Post29Component,
        pathMatch: "full",
    },
    {
        path: "insights/2021-limits-for-iras-401ks-and-more",
        component: Post30Component,
        pathMatch: "full",
    },
    {
        path: "insights/december-market-review",
        component: Post31Component,
        pathMatch: "full",
    },
    {
        path: "insights/january-market-review",
        component: Post32Component,
        pathMatch: "full",
    },
    {
        path: "insights/navigating-your-required-minimum-distribution",
        component: Post33Component,
        pathMatch: "full",
    },
    {
        path: "insights/a-checklist-for-when-a-spouse-or-parent-passes",
        component: Post34Component,
        pathMatch: "full",
    },
    {
        path: "insights/february-market-review",
        component: Post35Component,
        pathMatch: "full",
    },
    {
        path: "insights/retirement-seen-through-your-eyes",
        component: Post36Component,
        pathMatch: "full",
    },
    {
        path: "insights/march-market-review",
        component: Post37Component,
        pathMatch: "full",
    },
    {
        path: "insights/the-new-inherited-ira-rules",
        component: Post38Component,
        pathMatch: "full",
    },
    {
        path: "insights/april-market-review",
        component: Post39Component,
        pathMatch: "full",
    },
    {
        path: "insights/what-in-the-world-are-ntfs",
        component: Post40Component,
        pathMatch: "full",
    },
    {
        path: "insights/may-market-review",
        component: Post41Component,
        pathMatch: "full",
    },
    {
        path: "insights/baseballs-300-million-players",
        component: Post42Component,
        pathMatch: "full",
    },
    {
        path: "insights/june-market-review",
        component: Post43Component,
        pathMatch: "full",
    },
    {
        path: "insights/billionaires-in-space",
        component: Post44Component,
        pathMatch: "full",
    },
    {
        path: "insights/july-market-review",
        component: Post45Component,
        pathMatch: "full",
    },
    {
        path: "insights/august-market-review",
        component: Post46Component,
        pathMatch: "full",
    },
    {
        path: "insights/wall-of-worry",
        component: Post47Component,
        pathMatch: "full",
    },
    {
        path: "insights/are-your-taxes-going-to-change",
        component: Post48Component,
        pathMatch: "full",
    },
    {
        path: "insights/september-market-review",
        component: Post49Component,
        pathMatch: "full",
    },
    {
        path: "insights/october-market-review",
        component: Post50Component,
        pathMatch: "full",
    },
    {
        path: "insights/gridlock-in-the-supply-chain",
        component: Post51Component,
        pathMatch: "full",
    },
    {
        path: "insights/november-2021-market-review",
        component: Post52Component,
        pathMatch: "full",
    },
    {
        path: "insights/end-of-the-year-money-moves-2021",
        component: Post53Component,
        pathMatch: "full",
    },
    {
        path: "insights/wise-decisions-with-retirement-in-mind",
        component: Post54Component,
        pathMatch: "full",
    },
    {
        path: "insights/december-2021-market-review",
        component: Post55Component,
        pathMatch: "full",
    },
    {
        path: "insights/updated-premiums-and-deductibles-for-medicare",
        component: Post56Component,
        pathMatch: "full",
    },
    {
        path: "insights/january-2022-market-review",
        component: Post57Component,
        pathMatch: "full",
    },
    {
        path: "insights/rmds-get-a-small-reprieve",
        component: Post58Component,
        pathMatch: "full",
    },
    {
        path: "insights/february-2022-market-review",
        component: Post59Component,
        pathMatch: "full",
    },
    {
        path: "insights/major-risks-to-family-wealth",
        component: Post60Component,
        pathMatch: "full",
    },
    {
        path: "insights/march-2022-market-review",
        component: Post61Component,
        pathMatch: "full",
    },
    {
        path: "insights/roth-ira-conversions",
        component: Post62Component,
        pathMatch: "full",
    },
    {
        path: "insights/april-2022-market-review",
        component: Post63Component,
        pathMatch: "full",
    },
    {
        path: "insights/taking-charge-of-your-financial-life",
        component: Post64Component,
        pathMatch: "full",
    },
    {
        path: "insights/may-2022-market-review",
        component: Post65Component,
        pathMatch: "full",
    },
    {
        path: "insights/why-dont-all-affluent-people-become-wealthy",
        component: Post66Component,
        pathMatch: "full",
    },
    {
        path: "insights/june-2022-market-review",
        component: Post67Component,
        pathMatch: "full",
    },
    {
        path: "insights/what-happens-when-there-are-no-beneficiaries",
        component: Post68Component,
        pathMatch: "full",
    },
    {
        path: "insights/july-2022-market-review",
        component: Post69Component,
        pathMatch: "full",
    },
    {
        path: "insights/the-behavior-gap-and-your-financial-health",
        component: Post70Component,
        pathMatch: "full",
    },
    {
        path: "insights/august-2022-market-review",
        component: Post71Component,
        pathMatch: "full",
    },
    {
        path: "insights/the-retirement-reality-check",
        component: Post72Component,
        pathMatch: "full",
    },
    {
        path: "insights/september-2022-market-review",
        component: Post73Component,
        pathMatch: "full",
    },
    {
        path: "insights/quarterly-economic-update",
        component: Post74Component,
        pathMatch: "full",
    },
    {
        path: "insights/october-2022-market-review",
        component: Post75Component,
        pathMatch: "full",
    },
    {
        path: "insights/annual-financial-to-do-list",
        component: Post76Component,
        pathMatch: "full",
    },
    {
        path: "insights/november-2022-market-review",
        component: Post77Component,
        pathMatch: "full",
    },
    {
        path: "insights/new-retirement-contribution-limits-for-2023",
        component: Post78Component,
        pathMatch: "full",
    },
    {
        path: "insights/december-2022-market-review",
        component: Post79Component,
        pathMatch: "full",
    },
    {
        path: "insights/managing-probate-when-setting-up-your-estate",
        component: Post80Component,
        pathMatch: "full",
    },
    {
        path: "insights/january-2023-market-review",
        component: Post81Component,
        pathMatch: "full",
    },
    {
        path: "insights/helpful-retirement-strategies-for-women",
        component: Post82Component,
        pathMatch: "full",
    },
    {
        path: "insights/february-2023-market-review",
        component: Post83Component,
        pathMatch: "full",
    },
    {
        path: "insights/how-to-prepare-for-tax-season",
        component: Post84Component,
        pathMatch: "full",
    },
    {
        path: "insights/march-2023-market-review",
        component: Post85Component,
        pathMatch: "full",
    },
    {
        path: "insights/retirement-strategies-avoid-pitfalls",
        component: Post86Component,
        pathMatch: "full",
    },
    {
        path: "insights/april-2023-market-review",
        component: Post87Component,
        pathMatch: "full",
    },
    {
        path: "insights/new-retirement-contribution-limits-for-2023-may",
        component: Post88Component,
        pathMatch: "full",
    },
    {
        path: "insights/may-2023-market-review",
        component: Post89Component,
        pathMatch: "full",
    },
    {
        path: "insights/how-to-manage-an-inheritance",
        component: Post90Component,
        pathMatch: "full",
    },
    {
        path: "insights/june-2023-market-review",
        component: Post91Component,
        pathMatch: "full",
    },
    {
        path: "insights/july-2023-market-review",
        component: Post92Component,
        pathMatch: "full",
    },
    {
        path: "insights/10-steps-to-retirement",
        component: Post93Component,
        pathMatch: "full",
    },
    {
        path: "insights/does-your-investment-portfolio-match-your-goals-for-retirement",
        component: Post94Component,
        pathMatch: "full",
    },
    {
        path: "insights/august-2023-market-review",
        component: Post95Component,
        pathMatch: "full",
    },
    {
        path: "insights/harvest-your-wealth-fall-planning-for-your-finances",
        component: Post96Component,
        pathMatch: "full",
    },
    {
        path: "insights/september-2023-market-review",
        component: Post97Component,
        pathMatch: "full",
    },
    
    { path: "**", redirectTo: "/" },
];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        WhoWeAreComponent,
        CarouselOurTeamComponent,
        WhatWeDoComponent,
        CFPComponent,
        SmartvestorComponent,
        ForbesComponent,
        FiduciaryComponent,
        RaymondJamesComponent,
        InsightsComponent,
        ContactUsComponent,
        CareersComponent,
        PressComponent,
        CurrentClientsComponent,
        FutureClientsComponent,
        Post1Component,
        Post2Component,
        Post3Component,
        Post4Component,
        Post5Component,
        Post6Component,
        Post7Component,
        Post8Component,
        Post9Component,
        Post10Component,
        Post11Component,
        Post12Component,
        Post13Component,
        Post14Component,
        Post15Component,
        Post16Component,
        Post17Component,
        Post18Component,
        Post19Component,
        Post20Component,
        Post21Component,
        Post22Component,
        Post23Component,
        Post24Component,
        Post25Component,
        Post26Component,
        Post27Component,
        Post28Component,
        Post29Component,
        Post30Component,
        Post31Component,
        Post32Component,
        Post33Component,
        Post34Component,
        Post35Component,
        Post36Component,
        Post37Component,
        Post38Component,
        Post39Component,
        Post40Component,
        Post41Component,
        Post42Component,
        Post43Component,
        Post44Component,
        Post45Component,
        Post46Component,
        Post47Component,
        Post48Component,
        Post49Component,
        Post50Component,
        Post51Component,
        Post52Component,
        Post53Component,
        Post54Component,
        Post55Component,
        Post56Component,
        Post57Component,
        Post58Component,
        Post59Component,
        Post60Component,
        Post61Component,
        Post62Component,
        Post63Component,
        Post64Component,
        Post65Component,
        Post66Component,
        Post67Component,
        Post68Component,
        Post69Component,
        Post70Component,
        Post71Component,
        Post72Component,
        Post73Component,
        Post74Component,
        Post75Component,
        Post76Component,
        Post77Component,
        Post78Component,
        Post79Component,
        Post80Component,
        Post81Component,
        Post82Component,
        Post83Component,
        Post84Component,
        Post85Component,
        Post86Component,
        Post87Component,
        Post88Component,
        Post89Component,
        Post90Component,
        Post91Component,
        Post92Component,
        Post93Component,
        Post94Component,
        Post95Component,
        Post96Component,
        Post97Component,
        FilterPipe,
        FilterPipePress,
        FilterPipeCurrentClients,
        FilterPipeFutureClients,
        ArraySortPipe,
        ArraySortPipePress,
        ArraySortPipeCurrentClients,
        ArraySortPipeFutureClients,
        HeaderComponent,
        FooterComponent,
        SolelyComponent,
        InsightsListComponent,
        PressListComponent,
        SmallCarouselComponent,
        ComponentGuideComponent,
        CarouselComponent,
        TabsComponent,
        ShareComponent,
        WhatWeDoTab1Component,
        WhatWeDoTab2Component,
        WhatWeDoTab3Component,
        Service1Component,
        Service2Component,
        Service3Component,
        Service4Component,
        Service5Component,
        Service6Component,
        Service7Component,
        Service8Component,
        Service9Component,
        Service10Component,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "my-app" }),
        RouterModule.forRoot(routes, {
            anchorScrolling: "enabled",
            scrollPositionRestoration: "top",
        }),
        Angulartics2Module.forRoot(),
        TransferHttpCacheModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        JwSocialButtonsModule,
        NgbModule.forRoot(),
    ],
    providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
    bootstrap: [AppComponent],
})
export class AppModule {}
