import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap/carousel/carousel';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-whoweare',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WhoWeAreComponent{
  pauseOnHover:boolean = true;
  show:boolean = false;
  videoCover:boolean = true;
  activeCategory:number = 0;
  setSlideID=0;
  tabs: any[] = [
    {
      id: 1, 
      name: 'Glen D. Smith',
      title: 'CFP®, CRPC®',
      role: 'President',
      img: 'glendsmith_profile',
      mail: 'glen.smith@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/glen-d-smith-cfp%C2%AE-crpc%C2%AE-bb30aa5/'
    },
    {
      id: 2, 
      name: 'Robert L. Casey',
      title: 'AAMS®',
      img: 'robertlcasey_profile',
      role: 'Senior Vice President',
      mail: 'robert.casey@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/robert-casey-aams-9b192ab7/'
    },
    {
      id: 3, 
      name: 'Erica Doré',
      img: 'ericadore_profile',
      role: 'Chief Compliance Officer',
      mail: 'erica.dore@gdswealth.com',
      linkedin: ''
    },
    {
      id: 4, 
      name: 'Maria St. John',
      role: 'Client Associate',
      img: 'mariastjohn_profile',
      mail: 'maria.st.john@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/maria-st-john-7a958529/'
    },
    {
      id: 5, 
      name: 'Jordan Kast',
      title: 'CFP®',
      role: 'Financial Planner',
      img: 'jordankast_profile',
      mail: 'Jordan.Kast@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/jordan-kast/'
    },
    {
      id: 6, 
      name: 'Annie Adkison',
      role: 'Financial Planner',
      img: 'anniechumbley_profile',
      mail: 'Annie.Chumbley@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/annie-chumbley-80198238/'
    },
    {
      id: 7, 
      name: 'Victoria Rogers',
      title: 'CFP®',
      role: 'Financial Planner',
      img: 'victoriarogers_profile',
      mail: 'Victoria.Rogers@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/victoriarogers12/'
    },
    {
      id: 8, 
      name: 'Alex Schremmer',
      role: 'Financial Planner',
      img: 'alexschremmer_profile',
      mail: 'Alex.Schremmer@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/alex-schremmer-89b321186/'
    },
    {
      id: 9, 
      name: 'Sebastian Robert Carrillo',
      role: 'Financial Planner',
      img: 'sebastiancarrillo_profile',
      mail: 'Sebastian.Carrillo@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/sebastiancarrillo369/'
    },
    {
      id: 10, 
      name: 'Tyler Rucker',
      role: 'Director of Trading',
      img: 'tylerrucker_profile',
      mail: 'Tyler.Rucker@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/j-tyler-rucker-b4033684/'
    },
    {
      id: 11, 
      name: 'Gigi Hooper',
      role: 'Marketing Manager',
      img: 'gigihooper_profile',
      mail: 'Gigi.Hooper@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/georginahooper/'
    },
    {
      id: 12, 
      name: 'Tim Allin',
      role: 'Financial Planner',
      img: 'tim_allin_profile',
      mail: 'Timothy.Allin@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/timothyallin/'
    },
    {
      id: 13, 
      name: 'Alexandra Leonardi',
      role: 'Marketing Associate',
      img: 'alexandra_leonardi_profile',
      mail: 'Alexandra.Leonardi@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/alexandraleonardi/'
    },
    {
      id: 14, 
      name: 'Ruby Garcia',
      role: 'Client Associate',
      img: 'ruby_garcia_profile',
      mail: 'Ruby.Garcia@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/ruby-garcia-8bb915157/'
    },
    {
      id: 15, 
      name: 'Mattison Medelline',
      role: 'Client Associate',
      img: 'mattison_medelline_profile',
      mail: 'Mattison.Medelline@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/mattison-medelline-89709723b/'
    },
    {
      id: 16, 
      name: 'Taylor Thompson',
      role: 'Client Associate',
      img: 'taylor_thompson_profile',
      mail: 'Taylor.thompson@gdswealth.com',
      linkedin: 'https://www.linkedin.com/in/taylorfaucher/'
    }
  ];
  test: string;
  closeResult: string = '';


  onSlide(e:NgbSlideEvent):void {
    this.activeCategory = parseInt(e.current);
  }

  constructor(private modalService: NgbModal) {}

  open(content) {  
    this.modalService.open(content, {windowClass: 'modalSize'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}