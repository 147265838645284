import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  pauseOnHover= true;
  showLists= 'BLOG';
  mobile: boolean;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  
  ngOnInit() {
    this.mobile = /Android|webOS|iPhone|iPod/i.test(window.navigator.userAgent)||
    (window.navigator.userAgent === 'MacIntel' && navigator.maxTouchPoints > 1);
  }

  changeList(list) {
    this.showLists = list;
  } 

  ngAfterViewChecked() {
    const video:HTMLVideoElement = this.videoplayer.nativeElement;    

    if (video.paused) {
      video.play();
    }
  }
}
