import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'service-9',
  templateUrl: './service.component.html'
})
export class Service9Component{
  readonly url = "./../../../assets/json/services.json";
  services: any;

  constructor(private http: HttpClient) { }

  ngOnInit(){
    this.services = this.http.get(this.url)
  }

  slideUp(){
    let scrollToTop = (<any>window).setInterval(() => {
      let pos = (<any>window).pageYOffset;
      if (pos > 0) {
        (<any>window).scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        (<any>window).clearInterval(scrollToTop);
      }
    }, 5);
  }

}
