import { Component } from '@angular/core';

@Component({
  selector: 'app-forbes',
  templateUrl: './forbes.component.html',
  styleUrls: ['./forbes.component.scss']
})
export class ForbesComponent{
  show = false;

}