import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { Angulartics2Facebook } from 'angulartics2/facebook';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import {Component} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: any, private router: Router,
  public angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
  public angulartics2Facebook: Angulartics2Facebook,
  public angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      angulartics2GoogleTagManager.startTracking();
      angulartics2Facebook.startTracking();
      angulartics2GoogleAnalytics.startTracking();
    }
  }
  
  // onActivate(event) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     let scrollToTop = (<any>window).setInterval(() => {
  //       let pos = (<any>window).pageYOffset;
  //       if (pos > 0) {
  //         (<any>window).scrollTo(0, pos - 20); // how far to scroll on each step
  //       } else {
  //         (<any>window).clearInterval(scrollToTop);
  //       }
  //     }, 5);
  //   }
  // }
}
