import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tabs', 
  templateUrl: './tabs.component.html'
})
export class TabsComponent {
	pauseOnHover= true;
  // tabs = ['the tab 0', 'the tab 1', 'the tab 2', 'the tab 3'];
  @Input() tabs: string;
  paused = true;

  toString(i:any){
    return `${i}`;
  }
}