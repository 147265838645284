import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Angulartics2 } from "angulartics2";
import { Location } from "@angular/common";
import {
    FormControl,
    FormGroup,
    Validators,
    AbstractControl,
} from "@angular/forms";

@Component({
    selector: "app-contactus",
    templateUrl: "./contactus.component.html",
    styleUrls: ["./contactus.component.scss"],
})
export class ContactUsComponent implements OnInit {
    public formContact: FormGroup;

    constructor(
        private http: Http,
        @Inject(PLATFORM_ID) private platformId: any,
        public location: Location,
        public angulartics2: Angulartics2
    ) {}

    mailStatus = {
        sended: false,
        error: false,
    };

    trySubmit = false;

    smtp_server = `${environment.smtp_url}?env=${
        environment.production ? "prod" : "dev"
    }`;

    env: any = {};

    accentedCharacters =
        "àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ";

    emailControlValueChanged() {
        this.formContact.controls.email.statusChanges.subscribe(() => {
            if (this.formContact.get("email").status == "INVALID") {
                this.formContact
                    .get("phone")
                    .setValidators([
                        Validators.required,
                        Validators.pattern("^[0-9]*$"),
                    ]);
            } else {
                this.formContact.get("phone").clearValidators();
                this.formContact
                    .get("phone")
                    .setValidators([Validators.pattern("^[0-9]*$")]);
            }
            this.formContact.get("phone").updateValueAndValidity();
        });
    }
    phoneControlValueChanged() {
        this.formContact.controls.phone.statusChanges.subscribe(() => {
            if (this.formContact.get("phone").status == "INVALID") {
                this.formContact
                    .get("email")
                    .setValidators([
                        Validators.required,
                        Validators.pattern(
                            "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                        ),
                    ]);
            } else {
                this.formContact.get("email").clearValidators();
                this.formContact
                    .get("email")
                    .setValidators([
                        Validators.pattern(
                            "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                        ),
                    ]);
            }
            this.formContact.get("email").updateValueAndValidity();
        });
    }

    ngOnInit() {
        this.env = environment;

        function ValidateUrl(control: AbstractControl) {
            const re = new RegExp(
                "(?:(?:https?|ftp|file)://|www.|ftp.)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])"
            );
            if (re.test(control.value)) {
                return { validUrl: true };
            }
            return null;
        }

        this.formContact = new FormGroup({
            name: new FormControl("", [
                Validators.required,
                Validators.minLength(4),
                Validators.pattern(
                    "^[a-zA-Z_ " + this.accentedCharacters + "]*$"
                ),
            ]),
            email: new FormControl("", [
                Validators.required,
                Validators.pattern(
                    "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                ),
            ]),
            phone: new FormControl("", [
                Validators.required,
                Validators.pattern("^[0-9]*$"),
            ]),
            zip: new FormControl("", [Validators.pattern("^[0-9]*$")]),
            assets: new FormControl("", [Validators.required]),
            contact_method: new FormControl("", []),
            referr: new FormControl("", []),
            comments: new FormControl("", [
                Validators.maxLength(400),
                ValidateUrl,
            ]),
            recaptchatoken: new FormControl("", [Validators.required]),
            type: new FormControl("contactus"),
            section: new FormControl("contactus"),
        });

        this.emailControlValueChanged();
        //this.phoneControlValueChanged();
    }

    get name() {
        return this.formContact.get("name");
    }
    get email() {
        return this.formContact.get("email");
    }
    get phone() {
        return this.formContact.get("phone");
    }
    get zip() {
        return this.formContact.get("zip");
    }
    get assets() {
        return this.formContact.get("assets");
    }
    get contact_method() {
        return this.formContact.get("contact_method");
    }
    get recaptchatoken() {
        return this.formContact.get("recaptchatoken");
    }

    clearForm() {
        this.formContact.reset();
    }

    submit(e): void {
        e.preventDefault();
        if (this.formContact.get("email").status == "INVALID") {
            this.formContact
                .get("phone")
                .setValidators([
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                ]);
            this.formContact.get("phone").updateValueAndValidity();
        } else {
            this.formContact.get("phone").clearValidators();
            this.formContact
                .get("phone")
                .setValidators([Validators.pattern("^[0-9]*$")]);
            this.formContact.get("phone").updateValueAndValidity();
        }

        if (this.formContact.get("phone").status == "INVALID") {
            this.formContact
                .get("email")
                .setValidators([
                    Validators.required,
                    Validators.pattern(
                        "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                    ),
                ]);
            this.formContact.get("email").updateValueAndValidity();
        } else {
            this.formContact.get("email").clearValidators();
            this.formContact
                .get("email")
                .setValidators([
                    Validators.pattern(
                        "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                    ),
                ]);
            this.formContact.get("email").updateValueAndValidity();
        }

        if (
            this.formContact.get("email").value == "" &&
            this.formContact.get("phone").value == ""
        ) {
            this.formContact
                .get("email")
                .setValidators([
                    Validators.required,
                    Validators.pattern(
                        "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"
                    ),
                ]);
            this.formContact
                .get("phone")
                .setValidators([
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                ]);
            this.formContact.get("email").updateValueAndValidity();
            this.formContact.get("phone").updateValueAndValidity();
        }

        this.trySubmit = true;
        if (this.formContact.valid) {
            this.http
                .post(this.smtp_server, this.formContact.value)
                .toPromise()
                .then((res) => {
                    if (res.status === 200) {
                        this.mailStatus.error = false;
                        this.mailStatus.sended = true;
                        this.location.replaceState("/contact-us/success");
                        this.formContact.reset();
                        if (
                            environment.production &&
                            isPlatformBrowser(this.platformId)
                        ) {
                            this.angulartics2.pageTrack.next({
                                path: "/contactus/success",
                            });
                        }
                    }
                })
                .catch((err) => {
                    // console.log("test");
                    this.mailStatus.error = true;
                    this.mailStatus.sended = false;
                    this.location.replaceState("/contact-us/error");
                    if (
                        environment.production &&
                        isPlatformBrowser(this.platformId)
                    ) {
                        this.angulartics2.pageTrack.next({
                            path: "/contactus/error",
                        });
                    }
                });
        }
    }
}
