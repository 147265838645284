import { Component, OnInit, Inject, PLATFORM_ID, Input, ElementRef, ViewChild } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Http } from "@angular/http";
import { environment } from "../../../environments/environment";
import { Angulartics2 } from "angulartics2";
import { Location } from "@angular/common";
import { FormGroup, Validators, AbstractControl, FormBuilder } from "@angular/forms";

@Component({
    selector: "app-careers",
    templateUrl: "./careers.component.html",
    styleUrls: ["./careers.component.scss"],
})
export class CareersComponent implements OnInit {

    formCareer:FormGroup;
    trySubmit = false;    

    //DEV smtp_server = `https://gdswealth-mailer-dev.onrender.com/contact/career?env=${environment.production ? "prod" : "dev"}`;
    smtp_server = `${environment.smtp_url}contact/career?env=${environment.production ? "prod" : "dev"}`;
    filename:string = '';

    @ViewChild('fileUploadRef') fileUploadRef: ElementRef<HTMLInputElement>;
    
    env: any = {};
    success:boolean = false;
    error:boolean = false;
    sent:boolean = false;
    formData = new FormData;
    
    accentedCharacters = "àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ";

    constructor( 
        private fb:FormBuilder,
        private http: Http,
        @Inject(PLATFORM_ID) private platformId: any,
        public location: Location,
        public angulartics2: Angulartics2
        ) {
            this.formCareer = this.fb.group({
                name:['',[
                        Validators.required,
                        Validators.minLength(4),
                        Validators.pattern("^[a-zA-Z_ " + this.accentedCharacters + "]*$")
                ]],
                email:['', [
                        Validators.required,
                        Validators.pattern("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"),
                ]],
                phone: ['', [
                        Validators.required,
                        Validators.pattern("^[0-9]*$"),
                ]],
                linkedIn:['', [
                        Validators.required,
                        Validators.maxLength(100),
                        // Validators.pattern("^[A-Za-z0-9-]*^[^ \t]*$")
                        Validators.pattern("^[A-Za-z0-9-]*[^\r\n\t\f\v_ ]$")
                ]],
                resume:['', [
                    Validators.required,
                    this.ValidateSize,
                    this.ValidateFormat
                ]],
                salary: ['', [
                    Validators.required,
                    Validators.pattern("^[0-9]*$"),
                ]],
                role:['', [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.pattern("^[a-zA-Z ]*$")
                ]],
                reqrole:['', [
                    Validators.required,
                    Validators.minLength(2),
                    Validators.pattern("^[a-zA-Z ]*$")
                ]],
                comments:['', [
                    this.ValidateUrl,
                    Validators.pattern("^[A-Za-z0-9 .-]*$")
                ]],
                recaptchatoken: ['', [Validators.required]],
                type: ['career'],
                section: ['career']
            }) 

        }
        
    ngOnInit() {
        this.env = environment;
    }

    ValidateUrl(control: AbstractControl) {
        const re = new RegExp(
            "(?:(?:https?|ftp|file)://|www.|ftp.)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])"
        );
        if (re.test(control.value)) {
            return { validUrl: true };
        }
        return null;
    }

    //validate maximum size in kb
    ValidateSize(control: AbstractControl){
        return !!control.value && control.value.size > 2000000 ? {sizeExceed : true} : null;
    }
    //validate maximum size in kb
    ValidateFormat(control: AbstractControl){
        const validTypes = ['application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/msword']
        return !!control.value && validTypes.includes(control.value.type) ? null : {invalidFormat : true};
    }

    get nameValue() {
        return this.formCareer.get("name");
    }
    get emailValue() {
        return this.formCareer.get("email");
    }
    get phoneValue() {
        return this.formCareer.get("phone");
    }
    get linkedInValue() {
        return this.formCareer.get("linkedIn");
    }
    get resumeValue() {
        return this.formCareer.get("resume");
    }
    get salaryValue() {
        return this.formCareer.get("salary");
    }
    get roleValue() {
        return this.formCareer.get("role");
    }
    get reqroleValue() {
        return this.formCareer.get("reqrole");
    }
    get commentsValue() {
        return this.formCareer.get("comments");
    }
    get recaptchatoken() {
        return this.formCareer.get("recaptchatoken");
    }

    onFileSelected(ev){
        const file = (ev.target as HTMLInputElement).files[0];
        //console.log(file);
        if(file){
            this.filename = file.name;
            this.formCareer.patchValue({
                resume: file
            })
        }else{
            this.filename = '';
            this.formCareer.patchValue({
                resume: ''
            })
        }
        this.formCareer.get('resume').updateValueAndValidity();
        //this.formData.append('file',file);
    }

    clearForm() {
        this.formCareer.reset();
    }

    

    sendForm(){
        //console.log(this.formCareer.value);
        this.trySubmit = true;
        if(this.formCareer.valid){
            this.sent = true;

            
            this.formData.append("name", this.formCareer.get('name').value);
            this.formData.append("email", this.formCareer.get('email').value);
            this.formData.append("phone", this.formCareer.get('phone').value);
            this.formData.append("linkedIn", this.formCareer.get('linkedIn').value);
            this.formData.append("salary", this.formCareer.get('salary').value);
            this.formData.append("role", this.formCareer.get('role').value);
            this.formData.append("reqrole", this.formCareer.get('reqrole').value);
            this.formData.append("comments", this.formCareer.get('comments').value);
            this.formData.append("recaptchatoken", this.formCareer.get('recaptchatoken').value);
            this.formData.append("type", this.formCareer.get('type').value);
            this.formData.append("section", this.formCareer.get('section').value);
            this.formData.append("resume", this.formCareer.get('resume').value);

            this.http.post(this.smtp_server, this.formData)
            .subscribe( resp => {
                this.success = true;
                if (environment.production && isPlatformBrowser(this.platformId)){
                    this.angulartics2.pageTrack.next({
                        path: "/contactus/success",
                    });
                }
            }, error =>{
                this.error = true;
                if (environment.production && isPlatformBrowser(this.platformId)){
                    this.angulartics2.pageTrack.next({
                        path: "/contactus/error",
                    });
                }
            })
        }
    }
}
