// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

export const environment = {
    production: false,
    recaptcha_site_key: "6Le3HLIUAAAAABWIJFc0qURtc5msyRdzbYD_emJf",
    recaptcha_secret_key: "6Le3HLIUAAAAAEa-WK7RX1OnZdG5Z077Y1vHmrtC",
    smtp_url: "https://gdswealth-mailer-dev.onrender.com/",
    
};
