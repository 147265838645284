import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

document.addEventListener("DOMContentLoaded", () => {
    platformBrowserDynamic().bootstrapModule(AppModule);
});

const oldUrl = "glendsmithandassociates.com";
const newUrl = "https://gdswealth.com";

window.onload = () => {
    if (window.location.origin.includes(oldUrl) && environment.production) {
        window.location.href = newUrl;
    }
};
