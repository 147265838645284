import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  links = [
    {path: '/whoweare', label: 'Who We Are'},
    {path: '/whatwedo', label: 'What We Do'},
    {path: '/insights', label: 'Financial Insights'},
    {path: '/contactus', label: 'Contact Us'},
  ];
  year = (new Date()).getFullYear();
  
  constructor() { }

  ngOnInit() {
  }

}
