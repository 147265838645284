import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html'
})
export class Post35Component{
  readonly url = "./../../../assets/json/insights.json";
  posts: any;
  

  constructor(private http: HttpClient) { }

  ngOnInit(){
    this.posts = this.http.get(this.url)
  }

  slideUp(){
    let scrollToTop = (<any>window).setInterval(() => {
      let pos = (<any>window).pageYOffset;
      if (pos > 0) {
        (<any>window).scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        (<any>window).clearInterval(scrollToTop);
      }
    }, 5);
  }

}
