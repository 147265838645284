import { Component, OnInit } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-componentguide',
  templateUrl: './component-guide.component.html',
})
export class ComponentGuideComponent implements OnInit {
  slides = [
    {id: 1, content: '<h3>1</h3>'},
    {id: 2, content: '<h3>2</h3>'},
    {id: 3, content: '<h3>3</h3>'},
    {id: 4, content: '<h3>4</h3>'},
  ]
  tabs = [
    {id: 1, content: '<h3>Tab 1</h3>'},
    {id: 2, content: '<h3>Tab 2</h3>'},
    {id: 3, content: '<h3>Tab 3</h3>'},
    {id: 4, content: '<h3>Tab 4</h3>'},
  ]
  constructor() { }

  ngOnInit() {
  }

}
