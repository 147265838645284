import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-current-clients',
  templateUrl: './current-clients.component.html',
  styleUrls: ['./current-clients.component.scss']
})
export class CurrentClientsComponent implements OnInit {
  show = 5;
  
  readonly url = "./../../../assets/json/current-clients.json";
  events: any;

  filters = {
    sub1: null,
  }

  constructor(private route: ActivatedRoute, private http: HttpClient) { }

  showmore() {
    this.show = this.show + 5;
  }

  filter() {
    var target = (event.target as HTMLInputElement).innerText;
	this.show = this.events.length;
	target = target.trim();
    //console.log(target)
    if (target == 'Upcoming Events') {
      this.filters.sub1 = 'UPCOMING EVENTS';
    }
    if (target == 'Past Events') {
      this.filters.sub1 = 'PAST EVENTS';
    }
  }

  reset() {
    this.filters.sub1 = null;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['sub1']) {
        this.filters.sub1 = params['sub1'];
      }
    })
    this.events = this.http.get(this.url)
  }
}
