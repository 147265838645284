import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wwd-tab1',
  templateUrl: './wwdtab1.component.html',
  styleUrls: ['./wwdtabs.component.scss']
})
export class WhatWeDoTab1Component{
  activeTab = 1;

  setActiveTab(tab){
    this.activeTab = tab;
  }
  
}