import { Component } from '@angular/core';

@Component({
  selector: 'small-carousel',
  templateUrl: './small-carousel.component.html',
  styleUrls: ['./small-carousel.component.scss']
})
export class SmallCarouselComponent{
	pauseOnHover= true;
  constructor() {}
}
