import { Component } from '@angular/core';

@Component({
  selector: 'app-raymond-james',
  templateUrl: './raymond-james.component.html',
  styleUrls: ['./raymond-james.component.scss']
})

export class RaymondJamesComponent{
  activeCategory = 0;

  setActiveCategory(category){
    if (this.activeCategory == category){
      this.activeCategory = 0;
    } else{
      this.activeCategory = category;
    }
  }
}
