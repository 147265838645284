import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whowhatwedo',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class WhatWeDoComponent{
	pauseOnHover= true;
  activeCategory = 0;
  activeSubCategory = 0;

  tabs = [
    {
      id: 1, 
      name: 'FAMILIES & INDIVIDUALS',
      img: 'test'
    },
    {
      id: 2, 
      name: 'PROFESSIONALS, EXECUTIVES, & BUSINESS OWNERS',
      img: 'test'
    },
    {
      id: 3, 
      name: 'CORPORATION & BUSINESSES',
      img: 'test'
    },
  ]

  toString(i:any){
    return `${i}`;
  }

  setActiveCategory(category){
    this.activeSubCategory = 0;
    if (this.activeCategory == category){
      this.activeCategory = 0;
    } else{
      this.activeCategory = category;
    }
  }
  setActiveSubCategory(subCategory){
    if (this.activeSubCategory == subCategory){
      this.activeSubCategory = 0;
    } else{
      this.activeSubCategory = subCategory;
    }
  }
}